import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/lifeprint.css'

import tinLogo from '../../assets/images/gamerize/tinLogo.webp';
import challengeOne from '../../assets/images/lifeprint/challenge-1.webp';
import challengeTwo from '../../assets/images/lifeprint/challenge-2.webp';
import challengeThree from '../../assets/images/lifeprint/Challenge-3.webp';
import lifePrintBanner from '../../assets/images/lifeprint/lifeprint-banner.webp'
import lifePrintOne from '../../assets/images/lifeprint/lifeprint-1.webp'
import lifePrintDemoOne from '../../assets/images/lifeprint/lifeprint-demo-1.webp'
import lifePrintDemoTwo from '../../assets/images/lifeprint/lifeprint-demo-2.webp'
import lifePrintArchitectureOne from '../../assets/images/lifeprint/lifeprint-archit-1.webp'
import lifePrintArchitectureTwo from '../../assets/images/lifeprint/lifeprint-archit-2.webp'
import lifePrintTwo from '../../assets/images/lifeprint/lifeprint-2.webp'


class LifePrint extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <section>
          <img className="lifeprint-pic" src={lifePrintBanner} alt="banner" />
        </section>
        <section className="container lifeprint-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="mini-heading">Case Study - App Development</div>
              <div className="heading-one-j">LifePrint</div>

              <div className="row para-mini pt-0">
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeOne} alt="" />
                  <div className="subheading">The Challenge</div>
                  <p className="desc">
                  Lifeprint had an idea that they wanted to convert into a demoable POC to raise funding and build the product into a global success.
                  </p>
                </div>
                <div className="col-12 col-md-6 pl-0 pr-0">
                  <img className="icon" src={challengeTwo} alt="" />
                  <div className="subheading">The Solution</div>
                  <p className="desc">
                  We developed the native iOS and Android apps to support the Lifeprint printer and later scaled them to support the AR feature. 
                  We also modified the printer module architecture to handle seamless transitions from WiFi to Bluetooth and vice versa.
                  </p>
                </div>
              </div>
              <img className="icon" src={challengeThree} alt="" />
              <div className="subheading">The Impact</div>
              <p className="description">
              The client successfully secured $300k+ seed funding with Kickstarter and Indiegogo at the initial stages of the product journey. 
              Later on, Lifeprint became Apple’s retail partner, secured Warner Bros Harry Potter license, and successfully launched the app globally, 
              including China.
              </p>


              <div className="subheading-big mt-5">The Project</div>
              <p className='description'>AR Photo & Video Printer</p>
              <p className="description">
                In this age of digitalisation, the art of taking, printing and sharing photos and preserving our memories in photo albums has been lost. 
                While we take and share photos and videos all the time, we lose them as easily as we take them. Our collections are fragmented across our 
                phone camera rolls, cloud storage and social media applications and remain inaccessible to those outside those networks. LifePrint wanted 
                to bring back the magic of sitting together and enjoying a photo album with friends and family back into the mainstream by reimagining it 
                as an augmented reality experience. Their goal was to ‘be able to take an Apple Live Photo or a GoPro video and print it seamlessly for 
                your loved ones to enjoy together’. 
              </p>
              <p className="description">
              Lifeprint lets you share and experience digital content like it’s 1999 - but with a totally new twist!Lifeprint is a socially connected, 
              portable, Wifi and Bluetooth photo printer that helps users share and preserve memories with friends, family and loved ones. Apart from 
              social features, its companion Android and iOS mobile apps allow users to make and share Augmented Reality experiences from their photos 
              and videos with themed masks and filters from the beloved Harry Potter wizarding world.
              </p>
            </div>
          </div>
        </section>
        <section className="container">
          <div className="picture-lifeprint">
            <img className="w-100" src={lifePrintOne} alt="lifeprint" />
          </div>
        </section>
        <section className="container lifeprint-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="subheading-big">The Process</div>
              <p className="description">
              Tintash helped Lifeprint achieve this goal by building a demoable application for highly successful Kickstarter 
              and Indiegogo campaigns that landed more than $300K worth of seed funding. This enabled Lifeprint to raise the
               capital to manufacture and develop their product and to nurture a passionate photo sharing community. 
               Subsequently Tintash architected the cloud infrastructure and mobile apps that allowed Lifeprint to become an 
               Apple global retail partner. This culminated in Warner Bros agreeing to license the Harry Potter brand to Lifeprint 
               and allowed Tintash to create a set of themed printers and applications.

              </p>
            </div>
          </div>
        </section>
        <section className="container ">
          <div className="picture-lifeprint">
            <img className="subpic-lifeprint" src={lifePrintDemoOne} alt="lifeprint" />
            <img className="subpic-lifeprint" src={lifePrintDemoTwo} alt="lifeprint" />
          </div>
          <div className="img-title">Lifeprint’s Demo stations at local Apple stores</div>
        </section>
        <section className="container lifeprint-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <p className="description">
              Our partnership with Lifeprint spanned over a number of years and in the course of 
              this time, we worked on different feature sets for them during different phases.
               Each of these phases had individual set of milestones that we helped them achieve.
              </p>
            </div>
          </div>
        </section>


        <section className="container lifeprint-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="heading-phase">Phase 1</div>
              <div className="subheading-phase" >
                Converting the idea into a proof of concept, and helping Lifeprint successfully 
                raise seed funding  
                through Kickastarter campaign
                </div>
              <p className="description">
                Lifeprint came to us with a vision of bringing back the value of preserving memories through printed
                photographs. Their idea was to create an app that enables users to take pictures and videos from theirs cell phones, and print them anywhere and anytime using the Lifeprint printer. We worked with them
                from the inception of the product, till the end of the successful POC built; helping them in planning and
                scoping out the app functionality, defining user flows, prototyping and developing the app. The final
                POC allowed users to print images and videos, from a pre-existing gallery in the Lifeprint app, through
                the Lifeprint printer using bluetooth connectivity. Users could play the printed videos on their mobile
                screens by scanning them through the app. Lifeprint was successfully able to use the POC we built to
                raise seed funding in their Kickstarter campaigns.

              </p>
            </div>
            </div>
        </section>

        <section className="container lifeprint-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="heading-phase">Phase 2</div>
              <div className="subheading-phase" >
                Scaling the POC to enable printing and scanning of thousands of user generated content, and
                successfully becoming Apple’s Retail Partner.
                </div>
              <p className="description">
              After raising funding from Kickstarter and Indiegogo, Lifeprint was successful in landing an executive
                meeting with Apple to demo their product - the Lifeprint app and printer. For the demo, we started
                working on scaling the app to support reliable printing and scanning of the user generated content.
                It was important to not only have a reliable printing module which served the core functionality of
                printing, but also have an equally reliable and robust scanning module to provide the best experience
                possible to the users as a whole. To achieve this, we created a state machine for printing module, and
                an image recognition and playback experience which could support recognition of thousands of distinctuser images in a timely manner.
                <br />
                To make the app more engaging, we created the Lifeprint social network within the app where people
                could share, comment, Like photos and were able to print and scan those shared photos. The app also
                allowed users to fetch content from their Facebook, Twitter and Instagram albums

              </p>
            </div>
            </div>
        </section>
        <section className="container ">
          <div className="picture-lifeprint">
            <img className="subpic-lifeprint" style={{height: '450px'}} src={lifePrintArchitectureOne} alt="lifeprint" />
            <img className="subpic-lifeprint" style={{height: '450px'}} src={lifePrintArchitectureTwo} alt="lifeprint" />
          </div>
        </section>
        <section className="container lifeprint-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="heading-phase">Phase 3</div>
              <div className="subheading-phase" >
                Scaling the app for China using single code for multiple deployment environments and launching
                WiFi connectivity printer.
                </div>
                <br />
                <div className="subheading-phase" >
                Launching global support
                </div>
              <p className="description m-0">
                After becoming an Apple retail partner it was important that the app be available globally, and this
                meant targeting China as well. China was a major market that Lifeprint wanted to tap into but
                China’s firewall policy, blocking most of the Google cloud services that Lifeprint app relied upon, posed
                a huge challenge for Lifeprint. As a solution to that, we deployed the same python codebase to the
                AWS and app scale infrastructure to cater to the Chinese users. This single code multiple deployment
                environments infrastructure allowed us to produce a code that was easily scalable and maintainable
                and would work globally as well. Updating the infrastructure meant that the overall architecture of the
                app also needed to be updated. The diagrams below show a comparison of the different modules of the
                service architecture based on region the app is being used in.

              </p>
                <br />
                <div className="subheading-phase" >
                Scaling the AR experience
                </div>
              <p className="description m-0">
                We also worked on scaling our AR experience during this phase. Using new AR library (CraftAR), we
                were successful in enabling users to scan their desired content from within more than a million user
                generated images in just a few milliseconds.

              </p>

              <br />
                <div className="subheading-phase" >
                WiFi Printing
                </div>
              <p className="description m-0">
                During this phase, Lifeprint launched a new printer model which allowed users to connect to the app
                using not only Bluetooth, but WiFi connectivity as well. This update required us to make changes to the
                printer module architecture of the app in order to handle WiFi to Bluetooth, and vice-versa, transitions
                seamlessly. This was a complex challenge given the possibilities of data corruption and undefined
                states during the handoff. We used a comprehensive state machine pattern to avoid such situations.

              </p>
            </div>
            </div>
        </section>


        <section className="container lifeprint-section-portfolio">
          <div className="row py-md-6 mx-0">
            <div className="col-12">
              <div className="heading-phase">Phase 4</div>
              <div className="subheading-phase" >
              Winning a highly sought after Warner Bros Harry Potter license.
                </div>
              <p className="description">
                Lifeprint won Harry Potter themed printer branding rights from Warner Bros and launched a printer
                model that allowed the users to unlock Harry Potter theme and content inside the app by simply
                connecting to this printer. Harry Potter theme implementation required us to build considerable updates
                to overall application’s user interface and user experience design, exploring and implementing hardware
                based content unlocking. We used firebase to update user data in real-time and added a theme engine
                using protocol oriented programming (PoP) to handle all the UI/UX updates as soon as user connected to
                a harry potter printer.

              </p>
            </div>
            </div>
        </section>
        <section className="container">
          <div className="picture-lifeprint">
            <img className="w-100" src={lifePrintTwo} alt="lifeprint" />
          </div>
        </section>
        <section className="container tintash-sign py-5">
          <div className="row">
            <div className="col-12">
              <div className="tin-text"> Created and Developed by</div>
              <div className="tin-img">
                {' '}
                <img src={tinLogo} alt="bm" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default LifePrint;
